import React from "react"
import {graphql} from 'gatsby'
import Img from 'gatsby-image'
import styled from "styled-components"
import { hideVisually } from 'polished'
import Page from "../../components/page"

import imgAppeal from '../../images/course-dtm-appeal.jpg'
import imgMerit1 from '../../images/course-dtm-merit-1.png'
import imgMerit2 from '../../images/course-dtm-merit-2.png'
import imgMerit3 from '../../images/course-dtm-merit-3.png'
import imgReason1 from '../../images/course-dtm-reason-1.jpg'
import imgReason2 from '../../images/course-dtm-reason-2.jpg'
import imgReason3 from '../../images/course-dtm-reason-3.jpg'
import imgPoint1 from '../../images/course-dtm-point-1.png'
import imgPoint2 from '../../images/course-dtm-point-2.png'
import imgPoint3 from '../../images/course-dtm-point-3.png'
import imgAppLogicPro from '../../images/course-dtm-app-logic-pro.jpg'
import imgAppFlStudio from '../../images/course-dtm-app-fl-studio.jpg'
import imgAppAbletonLive from '../../images/course-dtm-app-ableton-live.jpg'
import imgRelease1 from '../../images/course-dtm-release-1.jpg'
import imgRelease2 from '../../images/course-dtm-release-2.jpg'

const PageDtmCourse = ({data}) => {
  return (
    <Page title="DTMコース" showTitleBar={false}>
      <Hero>
        <Img fluid={data.imgHero.childImageSharp.fluid} style={{ position: "absolute", zIndex: "1", top: "0px", left: "0px", width: "100%", height: "100%"}} />
        <header>
          <div>
            <h1>海外の音楽シーンを学び、本物をクリエイトする。</h1>
            <p>&ldquo;聴くから作る&rdquo;音楽へ</p>
          </div>
        </header>
      </Hero>

      <Appeal>
        <div class="inner">
          <div className="box">
            <p className="box__intro">
              関西最大の当DJアカデミーが運営する<br />
              <span>ダンスミュージック専門</span>の<br />
              DTMアカデミーでは
            </p>
    
            <p className="box__call">
              <span className="box__call__inner"><span>世界水準</span>の<span>音楽制作</span>を<span>学べる</span></span>
            </p>
    
            <p className="box__final">
              国内でも数少ない環境を用意しています
            </p>
          </div>
  
          <div className="merits">
            <div className="merit"><span>業界最安値！基礎学習¥29,800</span></div>
            <div className="merit"><span>世界リリース確約制作からリリースを完全サポート</span></div>
            <div className="merit"><span>世界基準！海外のトップエンジニアからのサポート</span></div>
          </div>
        </div>
      </Appeal>

      <Reason>
        <Img fluid={data.imgReason.childImageSharp.fluid} style={{
          position: "absolute", zIndex: "1", top: "0", left: "0", width: "100%", height: "100%"}} />
        <Inner>
          <h2><span>DTMアカデミーが選ばれる理由</span></h2>
  
          <div className="point point1">
            <h3><span>Point 1</span></h3>
            <div className="point__photo"></div>
            <div className="point__content">
              <p>
                世界最大のダンスミュージックレーベル<span>"Spinnin' Records"</span>傘下でトップアーティスト"Oliver Heldens"が主宰するHELDEEPからリリース実績があります。
              </p>
              <p>また同じく世界的トップアーティスト"Hardwell"のRevealed Recordingsからもリリース実績があります。</p>
            </div>
          </div>
          
          <div className="point point2">
            <h3><span>Point 2</span></h3>
            <div className="point__photo"></div>
            <div className="point__content">
              <p>
                <span>世界的トップレーベル</span>の<br />
                エンジニアのサポートを受けています。
              </p>
              <p>制作した音楽を高音質のミックス＆マスタリングで完成まで導きます。オリジナル楽曲は40サイト以上のメジャー配信サイトからのリリースが可能です。</p>
            </div>
          </div>
          
          <div className="point point3">
            <h3><span>Point 3</span></h3>
            <div className="point__photo"></div>
            <div class="point__content">
              <p>
                <span>低価格で専門学校と同等</span>の<br />
                授業を受けれます。
              </p>
              <p>東京スクールオブミュージック専門学校の授業カリキュラムと同等のレッスンをマンツーマンで受けることが出来ます。</p>
            </div>
          </div>
  
          <h3 class="app-header">プロも愛用するソフトを使用します</h3>
  
          <ul className="apps">
            <li><span>Logic Pro</span></li>
            <li><span>FL STUDIO</span></li>
            <li><span>Ableton Live</span></li>
          </ul>
        </Inner>
      </Reason>

      <Product>
        <Img fluid={data.imgProduct.childImageSharp.fluid} style={{
          position: "absolute", zIndex: "1", top: "0", left: "0", width: "100%", height: "100%"}} />
        <Inner>
          <h2><span>制作楽曲の紹介</span></h2>

          <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" title="AFSHeeN - Dancing With You (RIO Remix) [OUT NOW]" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/945458425&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style={{fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",fontWeight: "100",}}><a href="https://soundcloud.com/heldeeprecords" title="Heldeep Records" target="_blank" rel="noreferrer noopener" style={{color: "#cccccc", textDecoration: "none",}}>Heldeep Records</a> · <a href="https://soundcloud.com/heldeeprecords/afsheen-dancing-with-you-rio-remix-out-now" title="AFSHeeN - Dancing With You (RIO Remix) [OUT NOW]" target="_blank" rel="noreferrer noopener" style={{color: "#cccccc", textDecoration: "none"}}>AFSHeeN - Dancing With You (RIO Remix) [OUT NOW]</a></div>
          <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" title="THE SUN / Y&#x27;K ,PEACH,JEEN SEIGO" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/872797765&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style={{fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: "100",}}><a href="https://soundcloud.com/dj-kamiyama" title="JEEN SEIGO" target="_blank" rel="noreferrer noopener" style={{color: "#cccccc", textDecoration: "none", }}>JEEN SEIGO</a> · <a href="https://soundcloud.com/dj-kamiyama/the-sun" title="THE SUN / Y&#x27;K ,PEACH,JEEN SEIGO" target="_blank" rel="noreferrer noopener" style={{color: "#cccccc", textDecoration: "none"}}>THE SUN / Y&#x27;K ,PEACH,JEEN SEIGO</a></div>
          <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" title="Kuba" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/802371553&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style={{fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: "100",}}><a href="https://soundcloud.com/itsghin" title="GHIN" target="_blank" rel="noreferrer noopener" style={{color: "#cccccc", textDecoration: "none", }}>GHIN</a> · <a href="https://soundcloud.com/itsghin/kuba" title="Kuba" target="_blank" rel="noreferrer noopener" style={{color: "#cccccc", textDecoration: "none"}}>Kuba</a></div>
          <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" title="Toybox" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/659514254&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style={{fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: "100",}}><a href="https://soundcloud.com/toru_kk" title="TORUKK" target="_blank" rel="noreferrer noopener" style={{color: "#cccccc", textDecoration: "none", }}>TORUKK</a> · <a href="https://soundcloud.com/toru_kk/toybox-1" title="Toybox" target="_blank" rel="noreferrer noopener" style={{color: "#cccccc", textDecoration: "none"}}>Toybox</a></div>
          <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" title="Love Me Baby" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/687950434&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style={{fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: "100",}}><a href="https://soundcloud.com/user-721188943" title="Jerrry" target="_blank" rel="noreferrer noopener" style={{color: "#cccccc", textDecoration: "none", }}>Jerrry</a> · <a href="https://soundcloud.com/user-721188943/love-me-baby" title="Love Me Baby" target="_blank" rel="noreferrer noopener" style={{color: "#cccccc", textDecoration: "none"}}>Love Me Baby</a></div>
          <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" title="Another Fiction" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/609270237&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style={{fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: "100",}}><a href="https://soundcloud.com/theazure-herparamnesia" title="Fluxx" target="_blank" rel="noreferrer noopener" style={{color: "#cccccc", textDecoration: "none", }}>Fluxx</a> · <a href="https://soundcloud.com/theazure-herparamnesia/another-fiction" title="Another Fiction" target="_blank" rel="noreferrer noopener" style={{color: "#cccccc", textDecoration: "none"}}>Another Fiction</a></div>

          {/* <iframe width="100%" height="150" title="THE SUN / Y&#x27;K ,PEACH,JEEN SEIGO" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/872797765&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style={{ fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: "100" }}><a href="https://soundcloud.com/dj-kamiyama" title="JEEN SEIGO" target="_blank" rel="noopener noreferrer" style={{ color: "#cccccc", textDecoration: "none" }}>JEEN SEIGO</a> · <a href="https://soundcloud.com/dj-kamiyama/the-sun" title="THE SUN / Y&#x27;K ,PEACH,JEEN SEIGO" target="_blank" rel="noopener noreferrer" style={{ color: "#cccccc", textDecoration: "none" }}>THE SUN / Y&#x27;K ,PEACH,JEEN SEIGO</a></div>
          <iframe width="100%" height="150" title="Kuba" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/802371553&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style={{ fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: "100" }}><a href="https://soundcloud.com/itsghin" title="GHIN" target="_blank" rel="noopener noreferrer" style={{ color: "#cccccc", textDecoration: "none" }}>GHIN</a> · <a href="https://soundcloud.com/itsghin/kuba" title="Kuba" target="_blank" rel="noopener noreferrer" style={{ color: "#cccccc", textDecoration: "none" }}>Kuba</a></div>
          <iframe width="100%" height="150" title="Toybox" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/659514254&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style={{ fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: "100" }}><a href="https://soundcloud.com/toru_kk" title="TORUKK" target="_blank" rel="noopener noreferrer" style={{ color: "#cccccc", textDecoration: "none" }}>TORUKK</a> · <a href="https://soundcloud.com/toru_kk/toybox-1" title="Toybox" target="_blank" rel="noopener noreferrer" style={{ color: "#cccccc", textDecoration: "none" }}>Toybox</a></div>
          <iframe width="100%" height="150" title="Love Me Baby" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/687950434&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style={{ fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: "100" }}><a href="https://soundcloud.com/user-721188943" title="Jerrry" target="_blank" rel="noopener noreferrer" style={{ color: "#cccccc", textDecoration: "none" }}>Jerrry</a> · <a href="https://soundcloud.com/user-721188943/love-me-baby" title="Love Me Baby" target="_blank" rel="noopener noreferrer" style={{ color: "#cccccc", textDecoration: "none" }}>Love Me Baby</a></div>
          <iframe width="100%" height="150" title="Another Fiction" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/609270237&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style={{ fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: "100" }}><a href="https://soundcloud.com/theazure-herparamnesia" title="Fluxx" target="_blank" rel="noopener noreferrer" style={{ color: "#cccccc", textDecoration: "none" }}>Fluxx</a> · <a href="https://soundcloud.com/theazure-herparamnesia/another-fiction" title="Another Fiction" target="_blank" rel="noopener noreferrer" style={{ color: "#cccccc", textDecoration: "none" }}>Another Fiction</a></div> */}
          
        </Inner>
      </Product>

      <Support>
        <Img fluid={data.imgHero.childImageSharp.fluid} style={{
          position: "absolute", zIndex: "1", top: "0", left: "0", width: "100%", height: "100%", opacity: "0.2"}} />
        <Inner className="inner">
          <h2><span>充実のサポート</span></h2>
        </Inner>
        <div className="grid">
          <Img fluid={data.imgSupport.childImageSharp.fluid} className="photo" />
          <div className="content">
            <h3>今までの関西に無かった<br />世界水準の音楽を作るアカデミー</h3>
            {/* <p>
              一昔前の音楽制作授業では、難しい音楽理論やキーボード演習など、一曲を作るまでの道のりが非常に長く掛かりました。私達が提供するDTM学習は、効率なカリキュラムの元、最短距離で音楽制作の喜びと達成感を体感できるようにカリキュラムを作っています。なので挫折しない音楽制作授業を一番に意識しております。
            </p>
            <p>
              もちろん上級者の方のニーズにもしっかり応えれるように世界のトップエンジニアのサポートも充実しており、日々進化する世界の音楽制作の情報を共有出来る環境がしっかりあります。
            </p> */}
            <p>
              一昔前の音楽制作授業では、難しい音楽理論やキーボード演習など、一曲を作るまでの道のりが非常に長く掛かりました。
            </p>
            <p>
              私達が提供するDTM学習は、効率なカリキュラムの元、最短距離で音楽制作の喜びと達成感を体感できるようにカリキュラムを作っています。なので挫折しない音楽制作授業を一番に意識しております。
            </p>
            <p>
              もちろん上級者の方のニーズにもしっかり応えれるように世界のトップエンジニアのサポートも充実しており、日々進化する世界の音楽制作の情報を共有出来る環境がしっかりあります。
            </p>
            <a href="https://3faithsdjschool-mpc.com/support/" target="_blank" rel="noopener noreferrer">詳しく見る</a>
          </div>
        </div>
      </Support>

      <Release>
        <Inner>
          <h2><span>リリースサポート</span></h2>
          <img src={imgRelease1} alt=""/>
          <h3>当アカデミー生徒は40サイト以上の<br />配信サービスからリリースが出来る</h3>
          <img src={imgRelease2} alt="" />
          <h3>多くの人に聞いてもらう<br />ことにフォーカス</h3>
          <p>当アカデミーから全世界の楽曲配信サービスからリリースが可能です。作ることが出来たら今度は多くの人に聞いてもらうことにフォーカスを向けて行かなくてはいけません。</p>
          <p>アップルミュージック、Spotify、AWAなど多くの人が利用している配信サイトに自分の音楽を刻むことが可能です。</p>
        </Inner>
      </Release>

      <Price>
        <Inner>
          <h2><span>料金システム</span></h2>
          <div className="price">
            <div class="course-wrapper course-wrapper--master">
              {/* <div class="photo photo--master">
                <Img fluid={data.imgPrice.childImageSharp.fluid} style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", }} />
              </div> */}
              <div className="course course--master">
                <h3 className="course__title"><span>ダンスミュージック制作</span> <span>マスターコース</span></h3>
                <div className="popup">DJもDTMも学べる!!</div>
                <p className="course__hourly">１時間あたり <span className="course__price">4,800円</span></p>
                <p class="course__detail"><span>基礎48h+DJレッスン40h</span></p>
                <p className="course__fullprice">422,400円 / 48h</p>
              </div>
            </div>
            
            <div className="course course--basics">
              <h3 className="course__title">基礎コース 48h </h3>
              <p className="course__hourly">１時間あたり <span className="course__price">5,480円</span></p>
              <p className="course__fullprice">263,040円 / 48h</p>
            </div>
            
            <div className="course course--advanced">
              <h3 className="course__title">アドバンスコース</h3>
              <p className="course__hourly">１時間あたり <span className="course__price">7,000円</span></p>
            </div>
          </div>

          <h3 className="charged-separately"><span>別途下記の費用が掛かります</span></h3>

          <ul className="charged-separately-list">
            <li>※全ての料金に消費税が別途掛かります。</li>
            <li>※入学金10,000円（DJコース受講の方は掛かりません）</li>
            <li>※基礎動画教材31,600円（上級者は必要ありません）</li>
            <li>※ご自身のPCとDAWソフトをご用意下さい（持って無くても受講開始は可能です）</li>
          </ul>
        </Inner>
      </Price>
    </Page>
  )
}

export default PageDtmCourse

const Hero = styled.div`
  position: relative;
  padding: 5rem 0 0;
  color: white;
  height: 50vw;
  max-height: 900px;
  min-height: 400px;
  font-size: 1rem;

  @media (max-width: 920px) {
    font-size: 0.875rem;
  }
  
  @media (max-width: 820px) {
    font-size: 0.75rem;
  }

  @media (max-width: 630px) {
    font-size: 0.625rem;
  }

  @media (min-width: 1200px) {
    padding: 9rem 0 0;
  }
  
  @media (min-width: 1500px) {
    padding: 13rem 0 0;
  }

  header {
    position: relative;
    z-index: 2;
    width: calc(100% - 2rem);
    max-width: 1000px;
    margin: 0 auto;

    @media (max-width: 500px) {
      width: calc(100% - 1rem);
    }
    
    > div {
      position: relative;
      width: 66%;
      padding: 2rem 1rem 2rem 0;
      background: rgba(255, 96, 0, 0.8);

      @media (max-width: 800px) {
        width: 75%;
      }
      
      @media (max-width: 630px) {
        width: 80%;
      }
      
      @media (max-width: 480px) {
        width: 90%;
      }
      
      &::before {
        content: '';
        position: absolute;
        right: 100%;
        top: 0;
        height: 100%;
        width: 50vw;
        background: rgba(255, 96, 0, 0.8);
      }
    }
  
  }
  
  h1 {
    margin: 0;
    text-align: right;
    font-size: 1.5em;
    font-weight: 500;

    @media (max-width: 500px) {
      font-size: 1.25em;
    }
    
    @media (max-width: 370px) {
      font-size: 1em;
    }
  }
  
  p {
    text-align: right;
    margin: 0;
    font-size: 3em;

    @media (max-width: 420px) {
      font-size: 2.5em;
    }
    
    @media (max-width: 370px) {
      font-size: 2.25em;
    }
  }
`

const Appeal = styled.section`
  background: url(${imgAppeal}) no-repeat center/cover;

  .box {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 700px;
    margin: 0 auto 2rem;
    padding: 1rem;
    text-align: center;
    background: rgba(255,255,255,0.8);
    font-size: 1.75rem;
    color: black;

    @media (max-width: 700px) {
      font-size: 1.5rem;
    }
    
    @media (max-width: 550px) {
      font-size: 1.25rem;
    }
    
    @media (max-width: 450px) {
      font-size: 1rem;
    }
    
    @media (max-width: 380px) {
      font-size: 0.875rem;
    }

    &__intro {
      margin-top: 0;

      span {
        color: #ff6000;
      }
    }

    &__call {
      &__inner {
        display: inline-block;
        padding: 0.25em 0.5em;
        background: #ff6000;
        color: white;

        span {
          font-size: 1.33em;
        }
      }
    }

    &__final {
      margin-bottom: 0;
    }
  }
  
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 2rem);
    max-width: 900px;
    margin: 0 auto;
    padding: 0 0 2.5rem;
  }

  .merits {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .merit {
    width: calc((100% - 2rem) / 3);

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 100%;
    }

    span {
      ${hideVisually()}
    }

    &:nth-child(1) {
      background: url(${imgMerit1}) no-repeat center/contain;
    }

    &:nth-child(2) {
      background: url(${imgMerit2}) no-repeat center/contain;
    }

    &:nth-child(3) {
      background: url(${imgMerit3}) no-repeat center/contain;
    }
  }
`

const Reason = styled.section`
  position: relative;
  color: white;

  .point {
    position: relative;
    display: grid;
    grid-template-columns: 200px auto;
    margin: 6rem 0 3rem;
    background: white;

    @media (max-width: 650px) {
      grid-template-columns: auto;
      grid-template-rows: 200px auto;
    }

    h3 {
      position: absolute;
      left: 0;
      bottom: 100%;
      width: 200px;
      height: 50px;
      margin: 0;

      @media (max-width: 500px) {
        width: 160px;
        height: 40px;
      }

      span {
        ${hideVisually()}
      }
    }

    &__photo {
      grid-column: 1 / 2;

      @media (max-width: 650px) {
        grid-row: 1 / 2;
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        padding-top: 100%;
      }
    }
    
    &__content {
      grid-column: 2 / 3;

      @media (max-width: 650px) {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
      }
    }

    p {
      &:nth-child(1) {
        margin: 0;
        padding: 0.5rem;
        padding: 1.25rem 0.5rem;
        background: black;
        font-size: 1.625rem;
        text-align: center;

        @media (max-width: 500px) {
          font-size: 1.2rem;
          text-align: left;
        }

        span {
          color: yellow;
        }
      }

      &:nth-child(2) {
        margin: 0;
        padding: 0 0.5rem;
        padding: 1.25rem 0.5rem;
        color: black;
        font-size: 1.125rem;

        @media (max-width: 500px) {
          font-size: 1rem;
        }
      }
    }

    &.point1 {
      h3 {
        background: #ff6000 url(${imgPoint1}) no-repeat center/auto 75%;
      }

      p:nth-child(1) {
        font-size: 1.125rem;
      }

      .point__photo {
        background: url(${imgReason1}) no-repeat center/cover;
      }
    }

    &.point2 {
      h3 {
        background: #ff6000 url(${imgPoint2}) no-repeat center/auto 75%;
      }

      .point__photo {
        background: url(${imgReason2}) no-repeat center/cover;
      }
    }

    &.point3 {
      h3 {
        background: #ff6000 url(${imgPoint3}) no-repeat center/auto 75%;
      }

      .point__photo {
        background: url(${imgReason3}) no-repeat center/cover;
      }
    }
  }

  .app-header {
    text-align: center;
    text-shadow: 0 0 0.25em #9b3a00;
  }

  .apps {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;
    padding: 0;

    @media (max-width: 550px) {
      flex-direction: column;
    }

    li {
      position: relative;
      width: calc((100% - 2rem) / 3);

      @media (max-width: 550px) {
        width: 100%;
        margin: 1rem 0;
      }

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        padding: 0.25em 0.5em;
        background: rgba(0,0,0,0.7);
        white-space: nowrap;
        font-size: 1.25rem;
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        padding-top: 60.862069%;
      }

      &:nth-child(1) {
        margin-top: 0;
        background: url(${imgAppLogicPro}) no-repeat center/contain;
      }

      &:nth-child(2) {
        background: url(${imgAppFlStudio}) no-repeat center/contain;
      }

      &:nth-child(3) {
        margin-bottom: 0;
        background: url(${imgAppAbletonLive}) no-repeat center/contain;
      }
    }
  }
`

const Product = styled.section`
  position: relative;

  img {
    max-width: 100%;
  }

  iframe {
    margin: 2rem 0 0 0;
  }
`

const Support = styled.section`
  position: relative;
  padding-bottom: 4rem;

  .inner {
    padding-bottom: 0;
  }

  .grid {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 1fr 400px 600px 1fr;

    @media (max-width: 1032px) {
      grid-template-columns: 1rem 4fr 6fr 1rem;
    }
    
    @media (max-width: 768px) {
      grid-template-rows: auto auto;
    }
  }

  .photo {
    grid-column: 1 / 3;
    
    @media (max-width: 768px) {
      grid-column: 1 / -1;
      grid-row: 1 / 2;
    }
  }

  .content {
    grid-column: 3 / 4;
    padding-left: 1rem;

    @media (max-width: 768px) {
      padding-top: 1rem;
      padding-right: 1rem;
      grid-column: 1 / -1;
      grid-row: 2 / 3;
    }

    h3 {
      margin: 0 0 1rem;
      color: #ff6000;
      font-size: 1.625rem;

      @media (max-width: 500px) {
        font-size: 1.4rem;
      }
    }

    p {
      font-size: 1.125rem;
      line-height: 1.75;

      @media (max-width: 500px) {
        font-size: 1rem;
      }
    }

    a {
      display: block;
      padding: 0.5rem 0.5rem;
      background: black;
      color: white;
      text-align: center;
      text-decoration: none;
    }
  }
`

const Release = styled.section`
  h3 {
    text-align: center;
    color: #ff6000;
    font-size: 1.5rem;

    @media (max-width: 500px) {
      font-size: 1.2rem;
    }
  }

  img {
    max-width: 100%;
  }

  p {
    font-size: 1.125rem;
  }
`

const Price = styled.section`
  background: #f3f3f3;
  border-bottom: 0.25rem solid #ff6000;

  .price {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 1rem;
    width: calc(100% - 3.5rem);
    max-width: 800px;
    margin: 2rem auto 4rem;

    @media (max-width: 600px) {
      grid-template-columns: auto;
      grid-template-rows: auto auto auto;
      grid-gap: 2rem;
    }
  }

  .course-wrapper {
    position: relative;

    &--master {
      grid-column: 1 / -1;
      /* display: grid; */
      /* grid-template-columns: 225px 1fr; */
      
      @media (max-width: 700px) {
        /* grid-template-columns: auto; */
        /* grid-template-rows: 200px auto; */
      }
    }
  }

  .photo {
    position: relative;
    justify-self: stretch;
    align-self: stretch;
  }

  .course {
    --color: black;
    z-index: 1;
    border: 0.25rem solid var(--color);
    text-align: center;
  
    h3 {
      margin: 0 0 1rem;
      padding: 0.25rem 0 0.5rem;
      background: var(--color);
      color: white;

      @media (max-width: 768px) {
        span {
          display: block;
        }
      }
    }

    &__price {
      font-size: 2em;
    }

    &__detail {
      span {
        padding: 0.5rem 1rem;
        background: var(--color);
        color: white;
        border-radius: 3rem;
      }
    }

    &--master {
      --color: #e70000;
    }

    &--basics {
      --color: #24ab00;
    }

    &--advanced {
      --color: #0075ec;
    }
  }

  .popup {
    position: absolute;
    z-index: 2;
    left: -1rem;
    top: -1rem;
    padding: 0.5rem;
    border: 0.25rem solid #fcca00;
    background: #e70000;
    color: white;
    font-size: 0.875rem;

    @media (max-width: 600px) {
      font-size: 0.75rem;
    }
    
    @media (max-width: 570px) {
      transform-origin: left bottom;
      transform: rotate(-15deg);
      top: 0;
      left: -0.5rem;
    }

    @media (max-width: 500px) {
      transform: none;
      position: relative;
      display: inline-block;
      top: 0;
      left: 0;
      margin: 0 0 -0.5rem;
    }
  }

  .charged-separately {
    width: 100%;
    max-width: 450px;
    margin: 2rem auto 1rem;
    padding: 0.33em 0;
    background: black;
    color: white;
    text-align: center;
    font-size: 1rem;

    span {
      /* padding: 0.25rem 1rem;
      background: black;
      color: white; */
    }

    &-list {
      list-style: none;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      margin: 1rem auto 0;
      padding: 0;
      max-width: 800px;
      font-size: 0.875rem;
      grid-gap: 1rem;

      li:nth-child(1) {order: 1;}
      li:nth-child(2) {order: 3;}
      li:nth-child(3) {order: 2;}
      li:nth-child(4) {order: 4;}

      @media (max-width: 500px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-gap: 0.3rem;

        li:nth-child(1) {order: 1;}
        li:nth-child(2) {order: 2;}
        li:nth-child(3) {order: 3;}
        li:nth-child(4) {order: 4;}
      }
    }
  }

  @media (max-width: 600px) {
    .course-wrapper--master {
      order: 1;
    }
    .course--basics {
      order: 2;
    }
    .course--advanced {
      order: 3;
    }
  }
`

const Inner = styled.div`
  position: relative;
  z-index: 2;
  width: calc(100% - 2rem);
  max-width: 1000px;
  margin: 0 auto;
  padding: 4rem 0;

  h2 {
    margin: 0 0 3rem;
    text-decoration: none;
    border: 0;
    font-size: 1.5rem;
    font-weight: 500;

    @media (max-width: 500px) {
      font-size: 1.3rem;
    }

    span {
      display: inline-block;
      padding: 0.25em 0.5em;
      background: #ff6000;
      color: white;
    }
  }
`

export const pageQuery = graphql`
  query {
    imgHero: file(relativePath: { eq: "course-dtm-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgReason: file(relativePath: { eq: "course-dtm-reason.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgProduct: file(relativePath: { eq: "course-dtm-product.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgSupport: file(relativePath: { eq: "course-dtm-support.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgPrice: file(relativePath: { eq: "course-dtm-price.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`